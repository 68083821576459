import { ref, reactive } from "vue";
import c3api from "../c3api";
import { useRouter } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { unwrapReactive } from "../utils/unwrapReactive";

export default function useUsers() {
	const usersLoading = ref(false);
	const adminDialog = ref(false);
	const users = ref(null);
	const employees = ref(null);
	const user = ref(null);
	const woocommerceUsers = ref(null);
	const timezones = ref(null);
	const toast = useToast();
	const defaultLocalState = reactive({});
	const router = useRouter();
	const supportTicketsAssigned = ref(null);
	const supportTicketsCreated = ref(null);
	const userPermissionsDefinitions = ref([]);

	const fetchUsers = async (localState = defaultLocalState) => {
		usersLoading.value = true;
		const params = unwrapReactive(localState);
		c3api
			.get("/users", { params })
			.then((response) => {
				users.value = response.data;
			})
			.catch((error) => {
				console.log(error);
				toast.error(error.response?.data?.data.join(". "));
			});
		usersLoading.value = false;
	};

	const fetchEmployees = async (localState = defaultLocalState) => {
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get("/users/employees", { params });
			employees.value = response.data.data;
		} catch (error) {
			console.log(error);
			toast.error(error.response?.data?.data.join(". "));
		}
	};

	const fetchSupportTicketsAssigned = async (
		id,
		localState = defaultLocalState
	) => {
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(
				`/users/${id}/support_tickets_assigned`,
				{ params }
			);
			supportTicketsAssigned.value = response.data.data;
			return supportTicketsAssigned.value;
		} catch (error) {
			console.log(error);

			toast.error(error.response?.data?.data.join(". "));
		}
	};

	const fetchSupportTicketsCreated = async (
		id,
		localState = defaultLocalState
	) => {
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/users/${id}/support_tickets_created`, {
				params,
			});
			supportTicketsCreated.value = response.data.data;
			return supportTicketsCreated.value;
		} catch (error) {
			console.log(error);
			toast.error(error.response?.data?.data.join(". "));
		}
	};

	const fetchUser = async (id, localState = defaultLocalState) => {
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/users/${id}`, { params });
			return (user.value = response.data.data);
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
			router.push("/404");
		}
	};

	const getUserPermissionDefinitions = async (id) => {
		try{
		    const response = await c3api.get(`/users/${id}/permission_definitions`);
		    return (userPermissionsDefinitions.value = response.data.data);
		}catch(error){
		    console.error(error);
		    toast.error(error.response.data.data.join(". "));
		}
		
	}

	const getUserPermissionsLabels = () => {
		const resultPerms = [];
		const permissions = user.value.permissions;
		for (const permission in permissions) {
			if (permissions[permission] === true) {
				resultPerms.push(
					permission
						.split("_")
						.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
						.join(" ")
				);
			}
		}
		return resultPerms;
	};

	const createUser = async (localState = defaultLocalState) => {
		let params = unwrapReactive(localState);
		try {
			const response = await c3api.post("/users", params);
			toast.success("User created successfully!");
			router.push("/users");
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
	};

	const updateUser = async (id, localState = defaultLocalState) => {
		let params = unwrapReactive(localState);
		try {
			const response = await c3api.patch(`/users/${id}`, params);
			toast.success("User updated successfully!");
			return response.data;
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data.join(". "));
		}
	};

	const createAdminUser = async (localState = defaultLocalState) => {
		usersLoading.value = true;
		const params = unwrapReactive(localState);
		try {
			const response = c3api.post("/users/superadmins", params);
			toast.success(`New admin created successfully`);
			adminDialog.value = false;
			fetchUsers(localState);
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.data);
		}
		loading.value = false;
	};

	const deleteUser = async (user) => {
		const confirmation = confirm(
			`Are you sure you want to delete user ${user.name}?`
		);
		if (confirmation) {
			try {
				const response = await c3api.delete(`/users/${user.id}`);
				toast.success(`User ${user.name} deleted successfully`);
				return response.data;
			} catch (error) {
				console.error(error);
				toast.error(error.response.data.data);
			}
		}
	};

	const fetchTimezones = async () => {
		try {
			const response = await c3api.get(`/system/timezones`);
			timezones.value = response.data.data;
		} catch (error) {
			toast.error(error.response.data.data);
		}
	};

	const updatePin = async (id, params) => {
		c3api
			.patch(`/users/${id}/pin`, params)
			.then((response) => {
				toast.success(`User pin updated successfully`);
				return response.data;
			})
			.catch((error) => {
				console.error(error);
				toast.error(error.response.data.data.join(", "));
				return error.response;
			});
	};

	const updatePassword = async (id, params) => {
		const res = c3api
			.patch(`/users/${id}/update_password`, params)
			.then((response) => {
				toast.success(`User password updated successfully`);
				return response;
			})
			.catch((error) => {
				console.error(error);
				toast.error(error.response.data.data.join(", "));
				return error.response;
			});
		return res;
	};

	const updatePermissions = async (id, params) => {
		const res = c3api
			.patch(`/users/${id}/permissions`, params)
			.then((response) => {
				toast.success(`User permissions updated successfully`);
				return response;
			})
			.catch((error) => {
				console.error(error);
				toast.error(error.response.data.data.join(", "));
				return error.response;
			});
		return res;
	};

	const getWoocommerceUsers = async () => {
		try {
	        const response = await c3api.get(`/woocommerce/api_users`);
					console.log(response.data);
		    return (woocommerceUsers.value = response.data.data);
		} catch (error) {
		    console.error(error);
		    toast.error(error.response.data.data.join(". "));
		}
	}

	function updatePage(newPage) {
		router.push({ query: { page: newPage } });
	}

	return {
		users,
		usersLoading,
		employees,
		user,
		getUserPermissionsLabels,
		userPermissionsDefinitions,
		fetchUsers,
		fetchUser,
		createUser,
		deleteUser,
		fetchTimezones,
		fetchEmployees,
		timezones,
		adminDialog,
		fetchSupportTicketsAssigned,
		supportTicketsAssigned,
		fetchSupportTicketsCreated,
		supportTicketsCreated,
		updateUser,
		updatePin,
		updatePassword,
		updatePermissions,
		getUserPermissionDefinitions,
		updatePage,
		getWoocommerceUsers,
		woocommerceUsers
	};
}
